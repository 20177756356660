<template>
  <div>
    <div v-if="!done">
      <h4 class="text-xl font-bold">Transfer Instructions</h4>
      <p class="text-dark my-2">
        Make transfer of N210,000 to the account below to complete transaction,
        then come back to the app to select done.
      </p>
      <Hr class="my-4" />
      <div>
        <p class="text-dark">Bank:</p>
        <p class="text-darkGrey mt-3">Providus bank</p>
        <Hr class="my-4" />
      </div>
      <div>
        <p class="text-dark">Name:</p>
        <p class="text-darkGrey mt-3">Zilla Africa</p>
        <Hr class="my-4" />
      </div>
      <div>
        <p class="text-dark">Account No:</p>
        <div class="flex justify-between items-center mt-3">
          <p class="text-darkGrey">00998828172</p>
          <SecondaryButton
            text="Copy"
            p="py-1 px-5"
            fontSize="text-sm"
            @click="copy('00998828172')"
          />
        </div>
      </div>
      <Button class="mt-8" text="Done" width="w-full" @click="done = true" />
      <p class="text-center px-8 mt-6 text-dark">
        By proceeding, I confirm that I have read, understood and agreed to the
        Zilla
        <span class="text-darkGrey"
          >purchase policies and payment controls.</span
        >
      </p>
    </div>
    <div v-else>
      <span class="text-lg -mt-6 font-semibold text-brandPurple">
        One last confirmation
      </span>
      <p class="text-dark my-5">
        Have you done the transfer of N210,000 to the account provided.
      </p>
      <Button
        text="Yes, I have made the transfer"
        width="w-full"
        @click="$emit('refund')"
      />
      <p
        @click="done = false"
        class="text-center text-brandPurple mt-4 cursor-pointer font-semibold"
      >
        No, I have not
      </p>
    </div>
  </div>
</template>

<script>
import Hr from "@/UI/Hr";
import Button from "@/UI/Button";
import SecondaryButton from "@/UI/SecondaryButton";
import copy from "@/mixins/copy";
export default {
  mixins: [copy],
  components: {
    Hr,
    Button,
    SecondaryButton
  },
  data() {
    return {
      done: false
    };
  }
};
</script>

<style>
</style>